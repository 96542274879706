import { defineComponent } from 'vue';
import CreateEditRFID from '../CreateEditRFID';
import { useToast } from 'vue-toastification';
import { ModalBox, Table } from '@/components/UI';
export default defineComponent({
    name: 'RFIDTable',
    components: {
        CreateEditRFID,
        ModalBox,
        Table
    },
    setup: () => ({
        toast: useToast()
    }),
    data: () => ({
        rfidname: 'counterrfid',
        newrfid: { rfid: 'none' },
        editVisible: false,
        perpage: 100,
        editRFID: {
            id: 'test',
            rfid: 'test',
            text: 'text',
            action: {
                reaction: 'none'
            }
        },
        selectedRFID: null,
        message: '',
        currentpage: 1,
        fields: [
            { key: 'id', label: 'RFID' },
            { key: 'text', label: 'Text' },
            { key: 'action', label: 'Action' },
            { key: 'actions', label: '' }
        ],
        rfidactions: [
            { value: 'none', text: 'None' },
            { value: 'restart', text: 'Restart Room' },
            { value: 'reset', text: 'Reset Score' },
            { value: 'stop', text: 'Stop Room' },
            { value: 'test', text: 'Test Room' }
        ],
    }),
    computed: {
        rfids() {
            return this.$store.direct.state.rfid.specialRFID;
        },
        currentRFID() {
            return this.$store.direct.state.rfid.RFID[this.rfidname];
        },
        rfidtable() {
            const rfidlist = [];
            Object.values(this.rfids).forEach(element => {
                var _a, _b;
                const rfid = {
                    id: element.rfid,
                    text: element.text,
                    action: (_b = (_a = element.action) === null || _a === void 0 ? void 0 : _a.reaction) !== null && _b !== void 0 ? _b : 'no-action'
                };
                rfidlist.push(rfid);
            });
            return rfidlist;
        }
    },
    mounted() {
        this.$store.direct.dispatch.rfid.subscribe(this.rfidname);
        this.$store.direct.dispatch.rfid.subscribe(undefined);
    },
    destroyed() {
        this.$store.direct.dispatch.rfid.unsubscribe(this.rfidname);
        this.$store.direct.dispatch.rfid.unsubscribe(undefined);
    },
    methods: {
        startEdit(id) {
            this.editVisible = true;
            this.selectedRFID = id;
            const rfid = this.rfids[id];
            if (rfid === undefined) {
                console.error(`Could not edit RFID with id ${id}`);
                return;
            }
            this.editRFID.id = rfid.id;
            this.editRFID.rfid = rfid.rfid;
            this.editRFID.text = rfid.text;
            this.editRFID.action = rfid.action;
        },
        async updateRFID() {
            try {
                await this.$store.direct.dispatch.rfid.update(this.editRFID);
                this.editVisible = false;
                this.toast.success(`RFID ${this.editRFID.text} updated`);
            }
            catch (error) {
                this.toast.error(`RFID ${this.editRFID.text} was not update updated, ${error}`);
            }
        },
        async removeRFID(id) {
            try {
                await this.$store.direct.dispatch.rfid.delete(id);
                this.toast.success(`Delete RFID successfull`);
            }
            catch (error) {
                this.toast.error(`Could not delete RFID, ${error}`);
            }
        }
    }
});
