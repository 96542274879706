import { defineComponent } from 'vue';
import { Instagram } from '@/components/Widgets';
import { TeamStatus } from '@/types/teams';
import { CheckBox, Selector } from '@/components/UI';
export default defineComponent({
    name: 'InstagramTab',
    components: {
        Instagram,
        CheckBox,
        Selector
    },
    data: () => ({
        selectedTeam: 'all',
        list: true
    }),
    computed: {
        activeTeams() {
            return this.$store.direct.state.teams.active;
        },
        teamsOptions() {
            const options = [];
            options.push({ value: 'all', text: 'All Posts' });
            options.push({ value: 'onlyteams', text: 'Only with active teams' });
            Object.entries(this.activeTeams).forEach(([teamId, team]) => {
                options.push({ value: teamId, text: team.name });
            });
            return options;
        }
    },
    mounted() {
        this.$store.direct.dispatch.teams.subscribe(TeamStatus.Active);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.teams.unsubscribe(TeamStatus.Active);
    }
});
