import RFIDTable from '@/components/RFID/RFIDTable';
import CreateEditRFID from '@/components/RFID/CreateEditRFID';
import { defineComponent } from 'vue';
import { Button, ModalBox } from '@/components/UI';
export default defineComponent({
    name: 'RFIDTab',
    components: {
        RFIDTable,
        CreateEditRFID,
        Button,
        ModalBox
    },
    data: () => ({
        rfidname: 'counterrfid',
        visible: false,
        newrfid: {
            rfid: 'none',
            text: '',
            action: {
                reaction: 'none'
            }
        }
    }),
    computed: {
        rfids() {
            return this.$store.direct.state.rfid.specialRFID;
        },
        currentRFID() {
            return this.$store.direct.state.rfid.RFID[this.rfidname];
        }
    },
    watch: {
        currentRFID(newValue) {
            if (newValue === undefined)
                return;
            this.newrfid.rfid = newValue.id;
        }
    },
    mounted() {
        this.$store.direct.dispatch.rfid.subscribe(this.rfidname);
        this.$store.direct.dispatch.rfid.subscribe(undefined);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rfid.unsubscribe(this.rfidname);
        this.$store.direct.dispatch.rfid.unsubscribe(undefined);
    },
    methods: {
        addRFID() {
            this.$store.direct.dispatch.rfid.add(this.newrfid);
            this.visible = false;
        },
        updateRFID() {
            this.$store.direct.dispatch.rfid.update(this.newrfid);
        },
        deleteRFID(id) {
            this.$store.direct.dispatch.rfid.delete(id);
        }
    }
});
