import { defineComponent } from 'vue';
import { Selector } from '@/components/UI';
export default defineComponent({
    name: 'CreateEditRFID',
    components: {
        Selector
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        reactionoptions: [
            { value: 'none', text: 'None' },
            { value: 'restart', text: 'Restart Room' },
            { value: 'reset', text: 'Reset Score' },
            { value: 'stop', text: 'Stop Room' },
            { value: 'test', text: 'Test Room' },
            { value: 'demo', text: 'Fenix Teams' },
            { value: 'team', text: 'Team Badge' }
        ]
    })
});
